
// nav style

.hostie-menu{
    .hostie-desktop-menu{
        display: flex;
        justify-content: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        max-height: 100%;
        overflow: visible;
        margin: 0;
        .menu-item{
            margin: 0;
            color: var(--color-white);
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            text-transform: capitalize;
            padding: 0 30px;
            cursor: pointer;
            &.mega-menu.big{
                position: static !important;
            }
            @media(max-width:1200px){
                padding: 0 15px;
            }
            .hostie-dropdown-main-element,
            .main-element{
                padding: 28px 0;
                position: relative;
                display: block;
                &::after{
                    content: '';
                    position: absolute;
                    width: 0%;
                    height: 2px;
                    left: 0;
                    bottom: 28px;
                    background: var(--color-white);
                    transition: all .3s ease-in-out ;
                }
            }

            &:hover{
                .hostie-dropdown-main-element,
                .main-element{
                    color: var(--color-white);
                    &::after{
                        width: 100%;
                    }
                }
            }
            &.active{
                .hostie-dropdown-main-element{
                    color: var(--color-white);
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
        .hostie-has-dropdown{
            &:hover{
                .hostie-dropdown-main-element{
                    color: var(--color-white);
                    &::after{
                        width: 100%;
                    }
                }
                .hostie-submenu{
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            .hostie-dropdown-main-element{
                &::before{
                    content: '\f078';
                    position: absolute;
                    color: var(--color-white);
                    right: -20px;
                    top: 50%;
                    font-size: 12px;
                    transform: translateY(-50%);
                    font-family: 'Font Awesome 6 Pro';
                    @media(max-width:1200px){
                        right: -15px;
                    }
                }
            }
        }
        .hostie-submenu{
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            position: absolute;
            opacity: 0;
            width: 260px;
            visibility: hidden;
            overflow: visible;
            transform: translateY(15px);
            transition: all .3s ease-in-out;
            padding: 15px 0;
            background: var(--color-white);
            -webkit-box-shadow: 9px 9px 22px -1px rgba(0, 0, 0, 0.12);
            box-shadow: 9px 9px 22px -1px rgba(0, 0, 0, 0.12);
            z-index: 10;
            border-radius: 10px;
            .nav-item{
                margin: 0;
                transition: all .3s ease-in-out;
                position: relative;
                &:hover{
                    a.nav-link{
                        padding-left: 40px;
                        &::after{
                            width: 12px;
                        }
                    }
                    
                }
                a.nav-link{
                    display: block;
                    text-transform: capitalize;
                    color: var(--btc);
                    text-decoration: none;
                    padding: 10px 20px;
                    padding-right: 70px;
                    -webkit-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                    position: relative;
                    font-weight: 400;
                    font-size: 15px;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 2px;
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);
                        background: var(--btc);
                        transition: all .3s ease-in-out;
                    }
                    &.active{
                        padding-left: 40px;
                        color: var(--color-white);
                        &::after{
                            width: 12px;
                        }
                    }
                }
            }
        }
    }
} 
.sub-dropdown{
    position: relative !important;
    display: block !important;
    transition: all .3s ease-in-out;
    &.active{
        .sub-menu-link{
            color: var(--color-white) !important;
            padding-left: 40px !important;
            &::after{
                width: 12px !important;
            }
        }
        &::after{
            color: var(--color-white);
        }
    }
    &::after{
        content: '\f105';
        position: absolute;
        top: 50%;
        right: 15px;
        font-family: 'Font Awesome 6 Pro';
        transform: translateY(-50%);
        color: #fff9;
        transition: all .3s ease-in-out;
    }
    .hostie-submenu.third-lvl{
        opacity: 0 !important;
        min-width: 185px !important;
        left: 100% !important;
        top: -13% !important;
        margin: 0;
        border-radius: 0 !important;
        transform: translateY(25px) !important;
        transition: all .3s ease-in-out;
        pointer-events: none;
        &.base{
            visibility: hidden;
            transition: all .3s ease-in-out;
        }
        li{
            display: block;
            margin: 0;
            position: relative;
            &:hover{
                a{
                    padding-left: 40px;
                    color: var(--btc);
                    &::after{
                        width: 12px;
                    }
                }
            }
            a{
                display: block !important;
                padding: 10px 20px;
                position: relative;
                color: var(--btc);
                &::after{
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 2px;
                    top: 50%;
                    left: 20px;
                    transform: translateY(-50%);
                    background: var(--color-white);
                    transition: all .3s ease-in-out;
                }
                &.active{
                    padding-left: 40px;
                    color: var(--color-white);
                    &::after{
                        width: 12px;
                    }
                }
            }
        }
    }
    &:hover{
        &::after{
            color: var(--color-white);
        }
        .sub-menu-link{
            color: var(--color-primary);
        }
        .hostie-submenu.third-lvl.base{
            visibility: visible;
            opacity: 1!important;
            min-width: 185px !important;
            top: 0 !important;
            right: 3px;
            transform: translateY(5px) !important;
            pointer-events: all;
        }
    }
}


.header-two-content-main .main-nav-area {
    margin-left: -150px;
    @media #{$smlg-device} {
        margin-left: 0;
    }
    @media #{$md-layout} {
        display: none;
    }
    @media #{$sm-layout} {
        display: none;
    }
}

.side-bar {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: -100%;
    width: 465px;
    padding: 40px 30px;
    padding-top: 50px;
    height: 100%;
    display: block;
    background-color: white;
    backdrop-filter: blur(7px);
    z-index: 1900;
    transition: all 600ms ease;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow: visible;
    @media #{$large-mobile} {
        width: 315px;
    }
    .inner-main-wrapper-desk{
        .thumbnail{
            display: flex;
            justify-content: center;
            img{
                width: 60%;
                margin: auto;
            }
        }
        .inner-content{
            text-align: center;
            margin-top: 30px;
            p{
            max-width: 95%;
            text-align: center;
            margin: auto;
            }
            .title{
                font-weight: 600;
            }
            .footer{
                padding-top: 50px;
                margin-top: 40px;
                border-top: 1px solid #e8e8e8;
                .title{
                    font-weight: 500;
                }
                a.rts-btn{
                    margin: auto;
                }
            }
            .contact-information--sidebar{
                margin-top: 50px;
                .title{
                    margin-bottom: 20px;
                    font-size: 24px;
                }
                .single-info{
                    margin: 15px 0;
                    a{
                        // color: #2f3022;
                        // font-weight: 500;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
                .rts-social-style-one{
                    margin-top: 40px;
                    ul{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 15px;
                        list-style: none;
                        li{
                            margin: 0;
                            a{
                                height: 45px;
                                width: 45px;
                                border-radius: 50%;
                                border: 1px solid #dfdfdf;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                &:hover{
                                    background: var(--color-primary);
                                    border: 1px solid transparent;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
.side-bar.show {
    right: 0;
    overflow-y: auto;
}
.side-bar button {
    max-width: max-content;
    margin-right: auto;
    margin-left: -53px;
    margin-top: 0;
    position: absolute;
    i{
        color: #ffffff;
        height: 50px;
        width: 50px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        margin-top: -53px;
        font-size: 27px; 
        background: var(--color-primary);   
    }
}



.header-style-one.case-studies{
    .main-nav-area{
        ul{
            li{
                &>a.fluxi-dropdown-main-element{
                    color: #fff !important;
                }
            }
        }
    }
}


.header-style-one.case-studies.header--sticky.sticky{
    background: linear-gradient(258deg, #373551 8.48%, #212026 94.83%);
}

.rts-mega-menu{
    position: absolute;
    width: max-content;
    height: auto;
    top: 100%;
    transform: translateY(15px) translateX(-30%);
    left: 30%;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    transition: all 0.3s;
    background-color: #ffffff;
    display: inline-block;
    box-shadow: 0 36px 35px rgba(61, 60, 60, 0.08);
    transform-origin: 0 0 0;
    padding: 30px;
    border-radius: 10px;
}

.mega-menu-item{
    padding: 0;
    margin: 0;
    flex-direction: column;
    display: flex;
    align-items: flex-start !important;
    li{
        margin-bottom: 19px;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        @media(max-width:1200px){
            margin-bottom: 10px;
        }
        &:hover{
            a{
                border: 1px solid #E6E5FF;
                border-radius: 4px;
            }
        }
        a{
            display: flex !important;
            align-items: center;
            padding: 10px !important;
            border: 1px solid transparent;
            width: 90%;
            @media(max-width:1200px){
                width: 100%;
            }
            img{
                margin-right: 16px;
                padding: 10px;
                max-width: max-content;
                background: #F0F0FF;
                border-radius: 4px;
                @media(max-width:1200px){
                    margin-right: 8px;
                }
            }
            .info{
                p{
                    margin-bottom: 2px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    color: #083A5E;
                    @media(max-width:1200px){
                        font-size: 15px;
                    }
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #497696;
                    @media(max-width:1200px){
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
.hostie-has-dropdown.mega-menu:hover .rts-mega-menu{
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: translateY(5px) translateX(-30%);
}
.big{
    .rts-mega-menu{
        transform: translateY(15px) translateX(-50%);
        left: 50%;
        @media(max-width:1200px){
            width: 98%;
            padding: 25px;
        }
    }
}
.hostie-has-dropdown.mega-menu.big:hover .rts-mega-menu{
    transform: translateY(5px) translateX(-50%);
    visibility: visible;
}